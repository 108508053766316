<template>
    <div class="d-flex align-items-center justify-content-center h-100 test">
        <span v-for="number in rollingNumbers" :key="number.i" :class="number.class">{{number.value}}</span>
        <button @click="toggle" class="pause widget" :class="{paused: timer}">
            {{ buttonText }}
        </button>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Formatting from '@/util/formatting.js';

export default {
    data: function () {
        return {
            timer: !!window.timer,
            buttonText: 'Pause',
        };
    },
    beforeDestroy: function () {
        this.clearInterval();
    },
    mounted() {
        this.play();
    },
    methods: {
        ...mapActions([
            'goalsFood/getNumberInterval',
            "goalsWater/getNumberInterval",
            "goalsClimate/getNumberInterval",
            "goalsHealth/getNumberInterval"
        ]),
        toggle: function () {
            if (window.timer) {
                this.clearInterval();
                this.buttonText = 'Play';
                this.timer = false;
            } else {
                this.play();
                this.buttonText = 'Pause';
                this.timer = true;
            }
        },
        play: function () {
            this.clearInterval();
            window.timer = setInterval(() => {
                this['goalsFood/getNumberInterval']();
                this['goalsWater/getNumberInterval']();
                this['goalsClimate/getNumberInterval']();
                this['goalsHealth/getNumberInterval']();
            }, 50);

            this.timer = true;

            return () => clearInterval(window.timer)
        },

        clearInterval: function () {
            if (window.timer !== null) {
                clearInterval(window.timer);
                window.timer = null;
            }
        },
    },
    computed: {
        ...mapGetters(['goalsFood/number', "goalsWater/number", "goalsClimate/number", "goalsHealth/number"]),
        ...mapState({
            number(state, getters) {
                return getters[this.currentRouteModel + '/number'];
            },
        }),
        currentRouteModel: function() {
            if(!this.$route){
                return;
            }

            if(!this.$route.hasOwnProperty('meta')){
                return;
            }

            if(!this.$route.meta.hasOwnProperty('vuexModel')){
                console.error('this route is missing a vuexModel in routes/index')
                return;
            }
            return `${this.$route.meta.vuexModel}`;
        },
        rollingNumbers : function(){
            return Formatting.htmlizeNumbers(Formatting.commaSeparateNumber(_.ceil(this.number)));
        },
        detail : function(){
            if(this.number > 999){
                return Formatting.numberToText(this.number);
            }
        }
    }
}
</script>