<template>
    <div>
        <canvas id="myChart" width="400" height="400" :aria-label="canvasLabel" role="img"></canvas>
        <table aria-label="Chart data" class="sr-only">
          <thead>
          <tr>
            <th scope="col"></th>
            <th v-for="(year, index) in years" :key="index">
              {{ year.name }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(dataset, index) in tableData.datasets" :key="index">
            <th scope="row">{{ dataset.label }}</th>
            <td v-for="(year, yearIndex) in years" :key="yearIndex">
          <span v-if="dataset.data[yearIndex] === undefined ||
                      dataset.data[yearIndex] === null ||
                      dataset.data[yearIndex] === 'null'">-</span>
              <span v-else>{{ dataset.data[yearIndex] }}</span>
            </td>
          </tr>
          </tbody>
        </table>
    </div>

</template>

<script>
import store from '@/store/index.js';
import Chart from 'chart.js/auto';
import { mapGetters, mapState } from 'vuex';
export default {
    props: [],
    watch : {
        '$route' :function() {
            if(this.myChart){
                this.myChart.options = this.options;
                this.myChart.reset();
            }
        },
        count : function(){
           if(this.myChart){

                _.each(this.chartData.datasets, (dataset, i) => {
                    for (const attribute in dataset) {
                        if (dataset.hasOwnProperty(attribute)) {
                            this.myChart.data.datasets[i][attribute] = dataset[attribute]
                        }
                    }
                })
                //this.myChart.tooltip.update() // seems to have zero impact.
                this.myChart.update()
            }
        }
    },
    addListeners() {
        store.subscribe((mutation, state) => {
            if (mutation.type == "setViewport") {
                this._viewport = store.getters.viewport;
            }
        });
    },
    mounted() {
        this._viewport = store.getters.viewport;
        this.renderChart()
    },
    methods: {
        renderChart : function(){
            var plugins = [];

            const currentYear = new Date().getFullYear();
            if (this.chartData.labels.indexOf(currentYear) === -1) {
                window.flagEmptyCurrentYear = true;
                for (let i = 0; i < this.chartData.labels.length; i++) {
                    if (this.chartData.labels[i] === currentYear - 1 ) {
                        this.chartData.labels[i] = this.chartData.labels[i] + 1;
                    }
                }
            }
            

            let opts = {
                type: 'bar',
                data: this.chartData,
                options: this.options,
                plugins: plugins
            }
            var ctx = document.getElementById('myChart');
            this.myChart = new Chart(ctx, opts);
            //myChart._plugins.push(plugin)

        },
        formatNumber(num) {
            let numString = Math.round(num).toString()

            let numberFormatMapping = [
                [12, 't'],
                [9, 'B'],
                [6, 'M'],
                [3, 'k']
            ]
            for (let [numberOfDigits, replacement] of numberFormatMapping) {
                if(replacement == this.chartAxisUnits && num > 0) {
                    let decimal = '';
                    if (numString[numString.length - numberOfDigits] !== '0') {
                        if(numString[numString.length - numberOfDigits]) {
                            decimal = '.' + numString[numString.length - numberOfDigits];
                        }
                    }
                    numString = numString.substr(0, numString.length - numberOfDigits) + decimal + replacement;
                    break
                }
            }
            return numString
        }
    },
    computed: {
        ...mapState({
            years (state, getters) {
              return getters[this.currentRouteModel + '/years']
            },
            count (state, getters) {
              return getters[this.currentRouteModel + '/count']
            },
            staticValues (state, getters) {
              return getters[this.currentRouteModel + '/staticValues']
            },
            chartColorProjected (state, getters) {
              return getters[this.currentRouteModel + '/chartColorProjected']
            },
            chartColorActual (state, getters) {
              return getters[this.currentRouteModel + '/chartColorActual']
            },
        }),
        canvasLabel : function(){
            if(this.currentRouteModel){
                return `Chart ${this.currentRouteModel}`;
            }
        },
        currentRouteModel : function() {
            if(!this.$route){
                return;
            }

            if(!this.$route.hasOwnProperty('meta')){
                return;
            }

            if(!this.$route.meta.hasOwnProperty('vuexModel')){
                console.error('this route is missing a vuexModel in routes/index')
                return;
            }
            return `${this.$route.meta.vuexModel}`;
        },
        tableData: function() {
          let staticData = _.cloneDeep(this.chartData);
          if (staticData.datasets.length > 1) {
            staticData.datasets[0].data[1] = this.staticValues[1];
          }
          return staticData;
        },
        chartData: function() {
            return {
                labels: this.chartLabels,
                datasets: [
                    {
                        label: 'Actual',
                        backgroundColor: this.chartColorActual,
                        data: this.chartDataStaticWithCounter
                    }, {
                        label: 'Projected',
                        backgroundColor: this.chartColorProjected,
                        data: this.chartDataStaticValues
                    }
                ]
            }
        },
        chartLabels : function(){
            if(!this.years){
                return;
            }
            if(_.isEmpty(this.years)){
                return;
            }

            return _.map(this.years, (year) =>{
                return year.name
            });
        },
        chartAxisUnits : function(){
            if(this.currentRouteModel == "goalsClimate") {
                return "M";
            } else {
                return "B";
            }
        },
        chartDataStaticValues : function(){
            if(!this.staticValues){
                return [0,0,0,0];
            }
            let localStatic = [...this.staticValues];
            
            localStatic.splice(0, 1, 'null');
            return localStatic;
        },
        chartDataStaticWithCounter : function(){

            //only keep the first 2 entries to avoid a 0 value
            let localStatic = []
            localStatic.push(this.staticValues[0]);
            localStatic.push(this.count);
            return localStatic;
        },
        options: function() {
            var currentViewport = this._viewport;
            // console.log("currentViewport");
            // console.log(currentViewport);
            const that = this;
            let xFontSize = 22;
            let yFontSize = 14;
            let legendFontSize = 14;
            let tooltipNumberFontSize = 26;
            let titleMarginBottom = 3;

            if(currentViewport == "sm" || currentViewport == "xs" || currentViewport == "xxs" || currentViewport == "xxxs" || currentViewport == "xxxxs" || currentViewport == "") {
                xFontSize = 15;
                yFontSize = 11;
                legendFontSize = 11;
                tooltipNumberFontSize = 18;
                titleMarginBottom = 12;
            }

            return {
                showScale: true,
                scales: {
                    y: {
                        ticks: {
                            font: {
                                family: "'Interstate', sans-serif",
                                style: 'normal',
                                size: yFontSize,
                            },
                            beginAtZero: true,
                            callback: (value, index, values) => {
                                return this.formatNumber(value);
                            }
                        },
                        grid: {
                            display: true,
                            color: '#e2e0e0',
                            borderDash: [8, 3],
                        },
                        categoryPercentage: 1,
                        barPercentage: 0.4,
                        scaleFontSize: 40
                    },
                    x: {
                        ticks: {
                            font: {
                                family: "'Interstate', sans-serif",
                                style: 'normal',
                                size: xFontSize,
                            },
                        },
                        stacked: true,
                        grid: {
                            display: false,
                        }
                    }
                },
                plugins: {
                    tooltip: {

                        padding: {
                            top: 14,
                            right: 14,
                            bottom: 14,
                            left: 14,
                        },
                        backgroundColor: '#ffffff',
                        borderColor: this.chartColorActual,
                        borderWidth: 3,
                        titleFont: {
                            family: "'Interstate-Bold', sans-serif",
                            style: 'normal',
                            size: 14,
                        },
                        titleMarginBottom: titleMarginBottom,
                        titleColor: '#616365',
                        bodyFont: {
                            family: "'Interstate-Bold', sans-serif",
                            style: 'normal',
                            size: tooltipNumberFontSize,
                        },
                        bodyColor: '#007ac9',
             
                        footerFont: {
                            family: "'Interstate-Bold', sans-serif",
                            style: 'normal',
                            size: 14,
                        },
                        footerColor: '#616365',
                        cornerRadius: 0,
                        displayColors: false,
                        mode: 'index',
                        intersect: false,
                        mode: 'nearest',
                        caretSize: 10,
                        callbacks: {
                            title: (tooltipItems) => {
                                return tooltipItems[0].label + " " + tooltipItems[0].dataset.label;
                            },
                            label: (tooltipItems) => {
                                // let digits = tooltipItems.formattedValue.split('.')[0];
                                // digits = digits.toString().replace(/[0-9]/g, "0");
                                return tooltipItems.formattedValue.split('.')[0];
                            },
                            footer: function(tooltipItems) {
                                // if(tooltipItems[0].label == "2020" && tooltipItems[0].dataset.label == "Actual") {
                                //     let stat = "Helping manage 1.1 trillion gallons of water";
                                //     return [tooltipItems[0].formattedValue.split('.')[0], stat];
                                // } else {
                                    // return tooltipItems[0].formattedValue.split('.')[0];
                                //}
                                
                                let textItem = '';
                                if (window.flagEmptyCurrentYear) {
                                    const currentYear = new Date().getFullYear();
                                    tooltipItems.forEach(function(tooltipItem) {
                                        if (tooltipItem.dataIndex === 0) {
                                            textItem = `${currentYear - 1} actuals are being calculated and are coming soon.`;
                                        } else {
                                            textItem = '';
                                        }
                                    });
                                }
                               
                                return textItem;
                            },
                            // afterFooter: function(tooltipItems) {
                            //     if(tooltipItems[0].label == "2020" && tooltipItems[0].dataset.label == "Actual") {
                            //         let stat = "Helping manage 1.1 trillion gallons of water";
                            //         return stat;
                            //     }
                            // }
                        }
                    },
                    legend: {
                        onClick: null,
                        display: true,
                        position: 'top',
                        align: 'end',
                        labels: {
                            font: {
                                family: "'Interstate', sans-serif",
                                style: 'normal',
                                size: legendFontSize,
                            },
                        },
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                // horizontalLine: [{
                //  "y": 300000000000,
                //  "style": "#007ac9",
                //  "text": "2030 Goal"
                // }, {
                //  "y": 250000000000,
                //  "style": "#e27000",
                // }],
                annotation: {
                    annotations: {
                        // line1: {
                        //  type: 'line',couter
                        //  yMin: 300000000000,
                        //  yMax: 300000000000,
                        //  borderColor: 'rgb(255, 99, 132)',
                        //  borderWidth: 2,
                        // }
                        drawTime: "afterDatasetsDraw",
                        id: "hline",
                        type: "line",
                        mode: "horizontal",
                        scaleID: "y-axis-0",
                        value: 300000000000,
                        borderColor: "black",
                        borderWidth: 5,
                        label: {
                            backgroundColor: "blue",
                            content: "300 billion gallons of water",
                            enabled: true
                        },
                    }
                }
            }
        }
    }
}

</script>