<template>
  <nav aria-label="goals">
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <div class="nav-item flex-fill" v-for="(item, index) in navItems" :key="index">
        <router-link
            ref="navItems"
            :tabindex="currentPath == item.to ? 0 : -1"
            v-bind:class="[currentPath == item.to ? 'active' : '']"
            class="nav-link d-flex align-items-center justify-content-center"
            :id="item.id"
            @keydown.left.native="onTabNavigate(index - 1)"
            @keydown.right.native="onTabNavigate(index + 1)"
            @keydown.home.native="onTabNavigate(0)"
            @keydown.end.native="onTabNavigate(navItems.length - 1)"
            @click="tabNavigateTo(index)"
            data-toggle="tab" role="tab"
            :aria-controls="item.ariaControls"
            :aria-selected="currentPath == item.to ? 'true' : 'false'"
            :to="item.to">
          <div class="icon">
            <img :src="item.img" alt="">
          </div>
          <div class="text">
            {{item.label}}
          </div>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data : function () {
    return {
      navItems: [
        {
          label: 'Water',
          to: '/goals',
          id: 'nav-goals-tab-Water',
          ariaControls: 'nav-goals-Water',
          img: require('@/img/icons/water.svg')
        },
        {
          label: 'Food',
          to: '/goals/food',
          id: 'nav-goals-tab-Food',
          ariaControls: 'nav-goals-Food',
          img: require('@/img/icons/food.svg')
        },
        {
          label: 'Health',
          to: '/goals/health',
          id: 'nav-goals-tab-Health',
          ariaControls: 'nav-goals-Health',
          img: require('@/img/icons/health.svg')
        },
        {
          label: 'Climate',
          to: '/goals/climate',
          id: 'nav-goals-tab-Climate',
          ariaControls: 'nav-goals-Climate',
          img: require('@/img/icons/climate.svg')
        }
      ]
    }
  },
  computed : {
    currentPath : function(){
      return this.$route.path;
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to, from) {
        this.$nextTick(() => {
          const links = document.querySelectorAll('main .nav-item .nav-link');
          links.forEach(link => {
            if (link.getAttribute('aria-current')) {
              link.removeAttribute('aria-current');
            }
          });
        });
      }
    }
  },
  methods: {
    onTabNavigate: function(index) {
      if(index < 0) {
        index = this.navItems.length - 1;
      } else if(index >= this.navItems.length) {
        index = 0;
      }

      const navItem = this.navItems[index];
      if(this.$route.path !== navItem.to) {
        this.$router.push(navItem.to).then(() => {
          this.$nextTick(() => {
            this.$refs.navItems[index].$el.focus();
          });
        });
      }
    },
    tabNavigateTo: function(index) {
      const navItem = this.navItems[index];
      if(this.$route.path !== navItem.to) {
        this.$router.push(navItem.to).then(() => {
          this.$nextTick(() => {
            this.$refs.navItems[index].$el.focus();
          });
        });
      }
    },
  },
}
</script>
