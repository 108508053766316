<template>
	<div class="d-flex flex-column h-100 w-100 wiget_goals">
		<div class="goal_text">
			<h3 class="lg" v-if="currentRouteModel === 'goalsWater'" key="water">This year, our goal is to help customers conserve <strong>{{ formatNumber(staticValues[1]) }}</strong> gallons of{{ '\u00a0' }}water.</h3>
			<h3 class="lg" v-if="currentRouteModel === 'goalsFood'" key="food">This year, our goal is to help provide a year’s worth of high-quality and safe food to <strong>{{ formatNumber(staticValues[1]) }}</strong>{{ '\u00a0' }}people.</h3>
			<h3 class="lg" v-if="currentRouteModel === 'goalsHealth'" key="health">This year, our goal is to help clean <strong>{{ formatNumber(staticValues[1]) }}</strong>{{ '\u00a0' }}hands.</h3>
			<h3 class="lg" v-if="currentRouteModel === 'goalsClimate'" key="climate">This year, our goal is to help customers reduce greenhouse gas emissions by  <strong>{{ formatNumber(staticValues[1]) }}</strong> metric tons.</h3>
		</div>
		<div class="widget_chart d-flex justify-content-center flex-fill">
			<div class="bar">
				<div class="bar_progress" v-bind:style="{ height: percentage + '%' }" :class="{ 'percentage_outside' : percentage < 50}">
					<div class="percentage">{{ percentage }}<sup>%</sup></div>
				</div>
			</div>
			<div class="widget_tooltip_container" :class="{ 'reverse_tooltip' : percentage > 50}">
				<div class="widget_tooltip widget_tooltip_bottom" v-bind:style="{ bottom: percentage + '%' }">
					<div class="numbers">
						<numbers></numbers>
					</div>
				</div>
				<div class="widget_tooltip widget_tooltip_top" v-bind:style="{ top: (100-percentage) + '%' }">
					<div class="numbers">
						<numbers></numbers>
					</div>
				</div>
				<div class="widget_tooltip">
					<div class="numbers">
						<numbers></numbers>
					</div>
				</div>
			</div>
		</div>
		<div class="goal_summary">
			<p v-if="currentRouteModel === 'goalsWater'" key="water">So far, we have helped save <strong>{{ formatNumber(count) }}</strong> gallons.</p>
			<p v-if="currentRouteModel === 'goalsFood'" key="food">So far, we have helped provide <strong>{{ formatNumber(count) }}</strong> people with safe{{ '\u00a0' }}food.</p>
			<p v-if="currentRouteModel === 'goalsHealth'" key="health">So far, we have helped clean <strong>{{ formatNumber(count) }}</strong> hands.</p>
			<p v-if="currentRouteModel === 'goalsClimate'" key="climate">So far, we have helped save <strong>{{ formatNumber(count) }}</strong> metric tons.</p>
		</div>
	</div>
</template>

<script>
import store from '@/store/index.js';
import Chart from 'chart.js/auto';
import numbers from "@/components/goals/Numbers.vue";
import { mapGetters, mapState } from 'vuex';
export default {
	props: [],
	watch : {
		count: function() {
			
		}
	},
	addListeners() {
		store.subscribe((mutation, state) => {
			if (mutation.type == "setViewport") {
				this._viewport = store.getters.viewport;
			}
		});
	},
	data() {
		return {}
	},
	mounted() {
		this._viewport = store.getters.viewport;
	},
	methods: {
		formatNumber(num) {
			let numString = Math.round(num).toString()
			let numberFormatMapping = [
				[12, 'trillion'],
				[9, 'billion'],
				[6, 'million'],
				[3, 'thousand']
			]
			for (let [numberOfDigits, replacement] of numberFormatMapping) {
				if (numString.length > numberOfDigits) {
					let decimal = ''
					if (numString[numString.length - numberOfDigits] !== '0') {
						if(numString.length < 12) {
							decimal = '.' + numString[numString.length - numberOfDigits];
						}
					}
					numString = numString.substr(0, numString.length - numberOfDigits) + decimal + '\u00a0' + replacement
					break
				}
			}
			return numString
		}
	},
	computed: {
		...mapState({
			years (state, getters) {
			  return getters[this.currentRouteModel + '/years']
			},
			count (state, getters) {
			  return getters[this.currentRouteModel + '/count']
			},
			staticValues (state, getters) {
			  return getters[this.currentRouteModel + '/staticValues']
			},
			chartColorProjected (state, getters) {
			  return getters[this.currentRouteModel + '/chartColorProjected']
			},
			chartColorActual (state, getters) {
			  return getters[this.currentRouteModel + '/chartColorActual']
			},
		}),
		currentRouteModel: function() {
			if(!this.$route){
				return;
			}

			if(!this.$route.hasOwnProperty('meta')){
				return;
			}

			if(!this.$route.meta.hasOwnProperty('vuexModel')){
				console.error('this route is missing a vuexModel in routes/index')
				return;
			}
			return `${this.$route.meta.vuexModel}`;
		},
		percentage: function() {
			return Math.round((this.count / this.staticValues[1]) * 100);
		},
	},
	components : {
		numbers,
	}
}

</script>