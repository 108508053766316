module.exports = {
    numberToText: function(num) {
        function formatNumber(num) {
            return convert_trillions(num);
        }

        //http://stackoverflow.com/questions/5529934/javascript-numbers-to-words
        function convert_trillions(num) {
            if (num >= 1000000000000) {
                return (_.floor((num / 1000000000000) * 10) / 10).toFixed(1) + " trillion";
            } else {
                return convert_billions(num);
            }
        }

        function convert_billions(num) {
            if (num >= 1000000000) {
                return (_.floor((num / 1000000000) * 10) / 10).toFixed(1) + " billion";
            } else {
                return convert_millions(num);
            }
        }

        function convert_millions(num) {
            if (num >= 1000000) {
                return (_.floor((num / 1000000) * 10) / 10).toFixed(1) + " million";
            } else {
                return convert_thousands(num);
            }
        }

        function convert_thousands(num) {
            if (num >= 1000) {
                return (_.floor((num / 1000) * 10) / 10).toFixed(1) + " thousand";
            } else {
                return num;
            }
        }

        return formatNumber(num);
    },
    commaSeparateNumber: function(num) {
        while (/(\d+)(\d{3})/.test(num.toString())) {
            num = num.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
        }
        return num;
    },
    htmlizeNumbers: function(num) {

        let charactersArray = num.toString().split("");

        let i = 0;

        let characters = _.map(charactersArray, (letter) => {
            let characterize;

            switch (letter) {
                case " ":
                    characterize = "space";
                    break;
                case ",":
                    characterize = "comma";
                    break;
                case ".":
                    characterize = "period";
                    break;
                default:
                    if (!_.isNumber(letter)) {
                        characterize = "char";
                    }
                    characterize = "number";
            }

            i++;

            return {
                key: i,
                class: characterize,
                value: letter
            };
        });

        return characters;
    }
}